<template>
  <div class="app-container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/images/avatar.png" alt="" />
      </div>
      <el-form ref="form" :model="form" size="normal" :rules="rules" class="login_form" >
        <el-form-item prop="username">
          <el-input prefix-icon="el-icon-user-solid" v-model="form.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input prefix-icon="el-icon-lock" v-model="form.password" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex">
            <el-input prefix-icon="el-icon-key" v-model="form.validCode" style="width: 50%;" placeholder="请输入验证码"></el-input>
            <ValidCode @input="createValidCode" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="login">登 录</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="$router.push('/find')">忘记密码 >> </el-button>
          <el-button type="text" @click="handleContinueBuy()" style="float: right">账套续费 >> </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

  <el-footer style="padding: 0px;height: 60px;position: absolute;bottom:0px;width:100%;">
    <Footer />
  </el-footer>


  <el-dialog title="验证手机号" v-model="dialogVisibleMobile" :close-on-click-modal="false" class="dialog_form" width="500px">
    <div>
      <el-form ref="form" size="normal">
        <el-form-item prop="mobile">
          <el-input prefix-icon="el-icon-mobile" v-model="userMobile" placeholder="请输入手机号" @blur="handleBlur"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex">
            <el-input prefix-icon="el-icon-key" v-model="userValidCode" style="width: 70%;" placeholder="请输入验证码"></el-input>
            <div v-if="messageCodeVis" style="margin-left: 25px;" class="second-text">{{countdown}}秒后重新获取</div>
            <el-button v-else type="primary" style="margin-left: 25px;" :disabled="verifyPhone" @click="sendCode()">获取验证码</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100%" type="primary" :disabled="verifyPhone" @click="binding">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>

  <el-dialog title="会员续费" v-model="dialogVisibleWxQrCode" :close-on-click-modal="false" @close="closeDialog()" width="49%">
    <el-card class="element" style="margin: 0px auto 0;overflow:auto;">
      <div class="product-container">
        <div class="product-list">
          <div class="product-scroll product-scroll--sportsSvip">
            <div class="vu-scroll-content">
              <div style="min-height: 0px; pointer-events: auto;">
                <div class="product-list-container">
                  <ul class="product-list">
                    <li :class="flag == index?'product-item  product-item--active':'product-item'" v-for="(item, index) in options" :key="index" @click="handleChange(index)">
                      <h3 class="product-item__title">  {{ item.cycle }}{{ item.choice }} </h3>
                      <h2 class="product-item__price"><span class="symbol">¥</span><span class="number"> {{ item.money }}</span></h2>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div>
      <div class="pay-panel">
        <div class="mod_pay">
          <ul class="pay_bar">
            <li :class="wxFlag == 0?'item item1 current':'item item1'" @click="handlePayChange(0)">
              <i class="icon icon_wechat"></i><span class="txt">微信支付</span>
            </li>
            <li :class="wxFlag == 1?'item item1 current':'item item1'" @click="handlePayChange(1)">
              <i class="icon icon_zfb"></i><span class="txt">支付宝支付</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template #footer>
      <div style="width: 95%; margin: 0px auto;">
        <el-form size="normal">
          <el-form-item style="width: 500px;margin: 0px auto;">
            <el-button  style="width: 100%" type="primary" @click="handleBuy()">购买</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </el-dialog>


  <el-dialog title="续费信息" v-model="vis" :close-on-click-modal="false" @close="closeAccountDialog()" width="500px">
    <el-card>
      <div style="min-height: 50px">用户名：{{regUserName}}</div>
      <div style="min-height: 50px">有效期： <span style="margin-right: 10px;">{{regStartTime}}</span> —— <span style="margin-left: 10px;">{{regEndTime}}</span></div>
    </el-card>
    <el-form-item>
      <el-button style="width: 100%" type="primary" @click="closeAccountDialog">确 定</el-button>
    </el-form-item>
  </el-dialog>

  <el-dialog :title="wxFlag == 1?'支付宝支付':'微信支付'" v-model="qrcodeVisible" :close-on-click-modal="false" @close="closeQrcodeDialog()" width="500px">
    <el-card v-if="wxFlag == 0">
      <div style="justify-content: center;align-items: center;display: flex;" ref="qrcode" id="qrcode"></div>
      <img src="../assets/images/wx1.png" alt="wxIcon" style="position: absolute;top: 250px;left: 200px;width: 100px;height: 100px;">
    </el-card>
    <el-card v-if="wxFlag == 1">
      <iframe
          :srcdoc="qianHtml"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          width="400"
          height="400"
          style="overflow: hidden;justify-content: center;align-items: center;display: flex;"
      >
      </iframe>
    </el-card>
    <div style="width: 95%; margin: 20px auto 0px auto;">
      <el-form size="normal">
        <el-form-item style="width: 400px;margin: 10px auto;">
          <el-button  style="width: 100%" type="primary" @click="closeQrcodeDialog()">重新选择</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>


</template>

<script>
import ValidCode from "@/components/ValidCode";
import {updateUserEndTime, userLogin,verifyPhone} from "@/api/login";
import {smsSendCode, smsVerifyPhoneCode} from "@/api/sms";
import {fetchAllNextExpense} from "@/api/expense";
import '@/assets/css/account.css';
import QRCode from "qrcodejs2";
import {queryWxOrderStatus, wxPayQrcodePay} from "@/api/wxpay";
import {fetchOrderNo} from "@/api/orderNo";
import { queryAlipayOrderStatus, zfbPayQrcodePay} from "@/api/zfbpay";
import Footer from "@/components/Footer";

export default {
  name: "Login",
  components: {
    ValidCode,
    Footer
  },
  data() {
    return {
      form: {role: 1},
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      validCode: '',


      dialogVisibleMobile: false,
      messageCodeVis: false,
      countdown: 0,
      userMobile: "",
      userValidCode: '',
      verifyPhone:true,

      dialogVisibleWxQrCode: false,
      selectedCost: 0,
      flag: -1,
      wxFlag: 0,
      loadingFlag: true,
      outTradeNo: "",
      options: [],

      qrcodeVisible:false,
      qianHtml:"",
      wxTimer: null,
      zfbTimer: null,

      vis: false,
      regUserName: "",
      regStartTime: "",
      regEndTime: "",

    }
  },
  mounted() {
    sessionStorage.removeItem("user")
  },
  methods: {
    // 接收验证码组件提交的 4位验证码
    createValidCode(data) {
      this.validCode = data
    },
    login () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.form.validCode) {
            //this.$message.error("请填写验证码")
            return
          }
          if(this.form.validCode.toLowerCase() !== this.validCode.toLowerCase()) {
            this.$message.error("验证码错误")
            return
          }
          userLogin(this.form).then(res => {
            if (res.code === '0') {
              /*this.$message({
                type: "success",
                message: "登录成功"
              })*/
              sessionStorage.setItem("user", JSON.stringify(res.data))  // 缓存用户信息

              this.$router.push(res.data.permissionName)  //登录成功之后进行页面的跳转

            } else if(res.code === '-2'){
              this.$message({
                type: "error",
                message: res.msg
              })
              return;

            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
              return;
            }
          })
        }
      })
    },
    //账套续费
    handleContinueBuy(){
      //显示验证手机号弹框

      this.getAllExpense();

      this.dialogVisibleMobile = true;
      this.messageCodeVis = false;
      this.countdown = 0;
      this.userMobile = "";
      this.userValidCode = '';
      this.verifyPhone = true;
    },
    //判断手机号是否存在
    handleBlur() {
      // 调用接口的逻辑
      if(!this.userMobile) {
        this.$message({
          type: "error",
          message: "请输入手机号码"
        })
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.userMobile)){
        this.$message({
          type: "error",
          message: "请输入有效的手机号码"
        })
        return
      }

      let data =  {
        "mobile": this.userMobile,
      };
      verifyPhone(data).then(response => {
        // 验证手机号是否存在
        if (response.code === '0') {
          /*this.$message({
            message: '查验成功',
            type: 'success',
          });*/
          this.verifyPhone = false;
        }else{
          this.$message({
            type: "error",
            message: response.msg
          })
          this.verifyPhone = true;
          return;
        }
      })

    },
    //发送手机验证码
    sendCode(){
      if(!this.userMobile) {
        this.$message({
          type: "error",
          message: "请输入手机号码"
        })
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.userMobile)){
        this.$message({
          type: "error",
          message: "请输入有效的手机号码"
        })
        return
      }
      if(!this.verifyPhone){
        this.countdown = 120
        this.messageCodeVis = true
        this.startCountdown();
        this.handleSendCode();
      }

    },

    /* 倒计时 */
    startCountdown(){
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          console.log('验证码--清除定时器');
          clearInterval(this.intervalId);
          this.messageCodeVis = false;
        }
      }, 1000)
    },
    // 发送手机验证码
    handleSendCode() {
      let data =  {
        "mobile": this.userMobile,
      };
      smsSendCode(data).then(response => {
        // 支付成功后的页面跳转
        if (response.code === '0') {
          /*this.$message({
            message: '验证码发送成功',
            type: 'success',
          });*/
        }else{
          this.$message({
            type: "error",
            message: response.msg
          })
          console.log('验证码--清除定时器');
          clearInterval(this.intervalId);
          this.messageCodeVis = false;
        }
      })
    },
    //绑定
    binding(){

      if(!this.userMobile) {
        this.$message({
          type: "error",
          message: "请输入手机号码"
        })
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.userMobile)){
        this.$message({
          type: "error",
          message: "请输入有效的手机号码"
        })
        return
      }
      if(!this.userValidCode) {
        /*this.$message({
          type: "error",
          message: "请输入验证码"
        })*/
        return;
      }
      this.handleSmsVerifyCode();

    },
    // 校验手机验证码
    handleSmsVerifyCode() {
      let data =  {
        "mobile": this.userMobile,
        "validCode": this.userValidCode,
      };
      smsVerifyPhoneCode(data).then(response => {
        // 成功后的页面跳转
        if (response.code === '0') {

          this.handleOpen();

        }else{
          this.$message({
            type: "error",
            message: response.msg
          })
        }
      })
    },
    //打开支付框
    handleOpen() {
      this.dialogVisibleWxQrCode = true;

      this.selectedCost = 0;

      this.wxFlag = 0;
      this.loadingFlag = true;

      this.flag = -1;

    },
    getOrderNo() {
      // 从后台取出更新后的最新用户信息
      fetchOrderNo().then(response => {
        if (response.code === '0') {
          this.outTradeNo = response.data;
          this.handlePay();
        }
      });
    },
    //关闭支付框
    closeDialog(){
      this.dialogVisibleWxQrCode = false;
      console.log('关闭支付框---清除微信定时器')
      clearInterval(this.wxTimer)
      console.log('关闭支付框---清除支付宝定时器')
      clearInterval(this.zfbTimer)
    },
    //关闭二维码显示框
    closeQrcodeDialog(){
      this.qrcodeVisible = false;
      console.log('关闭支付框---清除微信定时器')
      clearInterval(this.wxTimer)
      console.log('关闭支付框---清除支付宝定时器')
      clearInterval(this.zfbTimer)
    },
    //切换选中支付金额
    handleChange(index) {
      if(this.flag != index){
        this.flag = index;
      }
    },
    handlePay(){
      let index = this.flag;
      let cost = this.getProductById(this.options[index].id);
      this.selectedCost = cost;

      if(this.wxFlag == 0){
        this.wxQrcodePay(this.selectedCost, this.options[index].id);
      }else if(this.wxFlag == 1){
        this.zfbQrcodePay(this.selectedCost, this.options[index].id);
      }

    },
    //切换支付方式
    handlePayChange(index) {
      if(this.wxFlag != index){
        this.wxFlag = index;
      }
    },
    //购买
    handleBuy(){
      console.log('切换支付方式---清除微信定时器')
      clearInterval(this.wxTimer);
      console.log('切换支付方式---清除支付宝定时器')
      clearInterval(this.zfbTimer);

      if(this.flag == -1){
        this.$message({
          type: "error",
          message: "请选择套餐"
        })
        return;
      }

      this.outTradeNo = null;

      //生成新的金额订单号
      this.getOrderNo();
    },
    //获取费用
    getAllExpense() {
      // 从后台取出更新后的最新用户信息
      fetchAllNextExpense().then(response => {
        if (response.code === '0') {
          this.options = response.data;
        }
      });
    },
    //获取详情
    getProductById(id) {
      for (let i = 0; i < this.options.length; i++) {
        if (id === this.options[i].id) {
          return this.options[i].money;
        }
      }
      return null;
    },
    //支付宝扫码支付
    zfbQrcodePay(selectedCost, expenseId) {
      let data =  {
        "totalFee": selectedCost,
        "orderNo": this.outTradeNo,
        "expenseId": expenseId,
        "accountStatus": "续费",
      };
      zfbPayQrcodePay(data).then(res => {
        if(res.code == 200){
          console.log(res);

          this.qianHtml = res.data.codeUrl;
          this.qrcodeVisible = true;

          this.loadingFlag = false;
          if(this.wxFlag == 1){
            //启动支付宝定时器
            this.zfbTimer = setInterval(() => {
              //查询订单是否支付成功
              this.handleAliPayQueryOrder()
            }, 3000)

          }

        }else{
          this.$message({
            type: "error",
            message: res.message
          })
        }

      })
    },

    //微信扫码支付
    wxQrcodePay(selectedCost, expenseId) {
      let data =  {
        "totalFee": selectedCost,
        "orderNo": this.outTradeNo,
        "expenseId": expenseId,
        "accountStatus": "续费",
      };
      wxPayQrcodePay(data).then(res => {
        if(res.code == 200){

          this.qrcodeVisible = true;
          this.$nextTick(() => {
            this.$refs.qrcode.innerHTML = ""; //清空二维码,避免生成多个二维码
            this.qrcode = new QRCode(this.$refs.qrcode,{
              //text: res.data.codeUrl, // 需要转换为二维码的内容
              width: 400,
              height: 400,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            })
            this.qrcode.makeCode(res.data.codeUrl);
          });
          this.loadingFlag = false;

          if(this.wxFlag == 0){
            //启动微信定时器
            this.wxTimer = setInterval(() => {
              //查询订单是否支付成功
              this.handleWxQueryOrder()
            }, 3000)

          }

        }else{
          this.$message({
            type: "error",
            message: res.message
          })
        }

      })
    },
    // 查询订单状态
    handleWxQueryOrder() {
      let data =  {
        "outTradeNo": this.outTradeNo,
      };

      if(this.wxFlag == 0){

        queryWxOrderStatus(data).then(response => {
          console.log('查询微信支付订单状态：' + response.data.tradeState);
          // 支付成功后的页面跳转
          if (response.data.tradeState === "SUCCESS") {
            this.$message({
              message: '支付成功',
              type: 'success',
            });
            console.log('微信支付订单成功--清除微信定时器')
            clearInterval(this.wxTimer);

            this.handleUpdateUserEndTime();

          } else if(response.data.tradeState === "CLOSED") {
            this.$message({
              type: "error",
              message: "订单已关闭"
            })
            console.log('微信支付订单关闭--清除微信定时器');
            clearInterval(this.wxTimer);
          }
        })

      }
    },
    // 查询支付宝订单状态
    handleAliPayQueryOrder() {
      let data =  {
        "outTradeNo": this.outTradeNo,
      };

      if(this.wxFlag == 1){

        queryAlipayOrderStatus(data).then(response => {
          console.log('查询支付宝订单状态：' + response.data.tradeState);
          // 支付成功后的页面跳转
          if (response.data.tradeState === "TRADE_SUCCESS") {
            this.$message({
              message: '支付成功',
              type: 'success',
            });
            console.log('支付宝支付订单成功--清除支付宝定时器')
            clearInterval(this.zfbTimer);

            this.handleUpdateUserEndTime();

          } else if(response.data.tradeState === "TRADE_CLOSED") {
            this.$message({
              type: "error",
              message: "订单已关闭"
            })
            console.log('支付宝支付订单关闭--清除支付宝定时器');
            clearInterval(this.zfbTimer);
          }
        })

      }
    },

    //更新用户过期时间
    handleUpdateUserEndTime() {
      //账号
      let regData =  {
        "outTradeNo": this.outTradeNo,
        "mobile": this.userMobile,
      };
      updateUserEndTime(regData).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: "续费成功"
          })
          this.dialogVisibleWxQrCode = false;
          this.qrcodeVisible = false;
          this.dialogVisibleMobile = false;
          this.regUserName = res.data.username;
          this.regStartTime = res.data.startTime;
          this.regEndTime = res.data.endTime;
          this.vis = true;

        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
      })
    },
    //关闭账号信息框
    closeAccountDialog(){
      this.vis = false;
    },
  }
}
</script>

<style scoped>
.login_box {
  width: 500px;
  height: 380px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*手机*/
@media screen and (max-width:600px){
  .login_box {
    width: 400px;
  }
}
/*平板*/
@media screen and (min-width:600px) and (max-width:960px){
  .login_box {
    width: 500px;
  }
}
/*PC*/
@media screen and (min-width:960px){
  .login_box {
    width: 500px;
  }
}

.avatar_box {
  height: 120px;
  width: 120px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #b7e0e4;
}
.avatar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.login_form {
  position: absolute;
  bottom: 0%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.second-text {
  color: #e60707;
}
</style>
